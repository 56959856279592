import React, { useState, useEffect, useRef } from 'react';
import { 
  Stack, 
  Chip, 
  Box, 
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText 
} from '@mui/material';
import { 
  LocalOffer, 
  FiberManualRecord, 
  Close, 
  Add,
  FileDownloadOutlined,
  FormatListBulleted,
  Description
} from '@mui/icons-material';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import useSearchParams from '../../hooks/useSearchParams';
import useDebounce from '../../hooks/useDebounce';
import {
  getAllTestScenarios,
  createTestScenario,
  deleteTestScenario,
  getTestScenarioTags,
  updateTestScenario,
  exportBrief,
  exportDetailed
} from '../../redux-store/testScenarioReducers/testScenarioActions';
import { getOwners } from '../../redux-store/currentUserActions';
import { getAllVariableSets } from '../../redux-store/variableSetReducers/variableSetActions';

import MainLayout from '../../layouts/MainLayout';
import TestScenariosList from './TestScenarioList';
import CustomFilter from '../Common/CustomFilter';
import SearchWithIcon from '../Common/SearchWithIcon';
import CustomButton from '../Common/CustomButton';
import TestSuiteAttach from "./TestSuiteAttach";

const defaultTestScenarioFilters = {
  tags: [],
  statuses: [],
  environmentSetId: null,
  buildStatus: null,
  ownerIds: [],
};
const statusesObj = {
  Draft: 'draft',
  Active: 'active',
  Archived: 'archived',
};
const statuses = Object.keys(statusesObj);
const buildStatuses = ['pass', 'fail'];

const TestScenarioListPage = ({ project }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');
  const { openSnackbar } = useSnackbar();

  // Export menu state
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const exportMenuOpen = Boolean(exportAnchorEl);

  const testScenarios = useSelector(
    (state) => state.testScenario.testScenariosList
  );
  const totalScenarios = useSelector((state) => state.testScenario.total);
  const selectedTestScenarios = useSelector(
    (state) => state.testScenario.selectedTestScenarios
  );
  const totalPages = useSelector((state) => state.testScenario.totalPages);
  const tags = useSelector((state) => state.testScenario.testScenarioTags);
  const owners = useSelector((state) => state.user.owners);
  const environmentSets = useSelector(
    (state) => state.variableSet.allVariableSets
  );
  const isLoading = useSelector((state) => state.testScenario.isLoading);

  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [searchText, setSearchText] = useState('');
  const tagsFilter = searchParams.get('tags');
  const statusesFilter = searchParams.get('statuses');
  const environmentSetIdFilter = searchParams.get('environmentSetId');
  const buildStatusFilter = searchParams.get('buildStatus');
  const ownerIdsFilter = searchParams.get('ownerIds');
  const parseFilter = (filter) => {
    let parsedFilter;
    try {
      parsedFilter = JSON.parse(filter) || [];
    } catch(e) {
      parsedFilter = [];
    }
    return parsedFilter;
  }
  const [appliedFilters, setAppliedFilters] = useState(
    {
      tags: tagsFilter ? parseFilter(tagsFilter) : [],
      statuses: statusesFilter ? parseFilter(statusesFilter) : [],
      environmentSetId: environmentSetIdFilter || null,
      buildStatus: buildStatusFilter || null,
      ownerIds: ownerIdsFilter ? parseFilter(ownerIdsFilter) : [],
    }
  );
  const lastFetchedPage = useRef(pageNumber);
  const isInitialMount = useRef(true);
  const debouncedSearchText = useDebounce(searchText, 1000);

  const hasAppliedFilters =
    appliedFilters.tags.length ||
    appliedFilters.statuses.length ||
    appliedFilters.environmentSetId ||
    appliedFilters.buildStatus ||
    appliedFilters.ownerIds.length;

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const handleExport = async (isDetailed) => {
    try {
      if (isDetailed) {
        // Call exportDetailed if isDetailed is true
        const response = await dispatch(exportDetailed({ projectId: project.id })).unwrap();
        openSnackbar({
          message: response.message,
          severity: "success",
        });
      } else {
        // Call exportBrief if isDetailed is false
        const response = await dispatch(exportBrief({ projectId: project.id })).unwrap();
        openSnackbar({
          message: response.message,
          severity: "success",
        });
      }
    } catch (error) {
      console.error('Export Failed:', error);
      if (error && error.message) {
        alert(`Error: ${error.message}`); // Display the error message to the user
      }
    }
    
    handleExportClose();
  };


  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    // keep page query-param in sync with state
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.set("page", pageNumber);
    window.history.pushState('', '', `?${queryParams.toString()}`);
  }, [pageNumber]);

  useEffect(() => {
    if (
      lastFetchedPage.current === pageNumber &&
      pageNumber > 1 &&
      !isInitialMount.current
    ) {
      // start to fetch from page#1 if filters or search text updates
      setPageNumber(1);
    } else {
      getTestScenariosList();
    }
  }, [pageNumber, appliedFilters, debouncedSearchText]);

  const getTestScenariosList = async () => {
    await dispatch(
      getAllTestScenarios({
        ...appliedFilters,
        statuses: appliedFilters.statuses.map((status) => statusesObj[status]),
        searchText: debouncedSearchText || null,
        projectId: project.id,
        pageNumber,
      })
    );

    lastFetchedPage.current = pageNumber;
  };

  const getFilters = async () => {
    await Promise.all([
      dispatch(getTestScenarioTags({ projectId: project.id })),
      dispatch(getOwners({ projectId: project.id })),
      dispatch(getAllVariableSets({ projectId: project.id, isEnvironment: true, pageNumber: 1 })),
    ]);
  };

  const createNewTestScenario = async () => {
    const testScenario = await dispatch(
      createTestScenario({
        projectId: project.id,
        title: `Test Scenario - ${totalScenarios + 1}`,
      })
    );
    if (testScenario?.payload?.id) {
      openSnackbar({
        message: "Test scenario created successfully.",
        severity: "success",
      });
      history.push(`/test-scenarios/${testScenario.payload.id}`);
    } else {
      openSnackbar({
        message: `Test suite creation failed!`,
        severity: "error",
      });
    }
  };

  const onDeleteTestScenario = async (testScenarioId) => {
    await dispatch(deleteTestScenario({ testScenarioId }));
    openSnackbar({
      message: "Test scenario deleted successfully.",
      severity: "success",
    });

    if (testScenarios.length === 1 && pageNumber !== 1) {
      onPageChange('', pageNumber - 1 > 0 ? pageNumber - 1 : 1);
    } else {
      getTestScenariosList();
    }
  };

  const onArchiveTestScenario = async (testScenarioId) => {
    await dispatch(
      updateTestScenario({ testScenarioId, data: { status: 'archived' } })
    );
    openSnackbar({
      message: "Test scenario archived successfully.",
      severity: "success",
    });
    getTestScenariosList();
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const applyFilters = (filters, reset=false) => {
    setPageNumber(1);
    if (reset) {
      updateUrl(filters);
      setAppliedFilters({...filters});
    } else {
      setAppliedFilters(prevState => {
        const newState = {...prevState, ...filters};
        updateUrl(newState);
        return newState;
      });
    }
  }

  const updateUrl = (filters={}) => {
    let queryStr = "?page=1";
    Object.keys(filters).forEach(f => {queryStr += (filters[f] && filters[f].length > 0) ? `&${f}=${Array.isArray(filters[f]) ? JSON.stringify(filters[f]) : filters[f]}` : ""});
    history.replace(history.location.pathname + queryStr);
  }

  const onResetFilters = () => applyFilters(defaultTestScenarioFilters, true);

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Test Scenarios"
      subtitleText={`Showing ${totalScenarios || 0} test scenario(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      rightSideContent={
        <>
          <SearchWithIcon
            placeholder="Search test scenarios"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{marginRight: "16px"}}
          />
          <CustomButton startIcon={<Add />} onClick={createNewTestScenario}>
            Add new test scenario
          </CustomButton>
        </>
      }
    >
      <Box sx={{ px: "20px", position: 'relative', mb: 1 }}>
        <Stack direction="row" spacing={1} sx={{ pr: '100px' }}>
          <CustomFilter
            id="tags-filter"
            label="Tags"
            icon={<LocalOffer />}
            searchInputPlaceholder="Select one or more options"
            options={tags || []}
            appliedFilters={appliedFilters.tags}
            setAppliedFilters={(tags) =>
              applyFilters({tags})
            }
          />
          <CustomFilter
            id="status-filter"
            label="Status"
            icon={<FiberManualRecord />}
            options={statuses || []}
            appliedFilters={appliedFilters.statuses}
            setAppliedFilters={(statuses) =>
              applyFilters({statuses})
            }
          />
          <CustomFilter
            id="last-run-filter"
            label="Last Run Result"
            type="radio"
            icon={<FiberManualRecord />}
            options={environmentSets || []}
            additionalOptions={buildStatuses || []}
            appliedFilter={appliedFilters.environmentSetId}
            setAppliedFilter={(environmentSetId) =>
              applyFilters({environmentSetId})
            }
            additionalAppliedFilter={appliedFilters.buildStatus}
            setAdditionalAppliedFilter={(buildStatus) =>
              applyFilters({buildStatus})
            }
          />
          <CustomFilter
            id="owner-filter"
            label="Owner"
            icon={<FiberManualRecord />}
            options={owners || []}
            appliedFilters={appliedFilters.ownerIds}
            setAppliedFilters={(ownerIds) =>
              applyFilters({ownerIds})
            }
          />
          {hasAppliedFilters ? (
            <Chip
              label="Reset all filters"
              deleteIcon={<Close />}
              onClick={onResetFilters}
              onDelete={onResetFilters}
              size="small"
              sx={{background: "rgb(229 229 229)", border: hasAppliedFilters ? "1px solid #6241d4" : "none", borderRadius: "4px", fontWeight: 500, padding: "14px 0"}}
            />
          ) : null}
          {selectedTestScenarios && selectedTestScenarios?.length > 0 ? (
            <TestSuiteAttach projectId={project.id} />
          ) : null}
        </Stack>
        <Box 
          sx={{ 
            position: 'absolute', 
            right: '24px', 
            top: '0',
            zIndex: 1,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            height: '32px'
          }}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<FileDownloadOutlined />}
            onClick={handleExportClick}
            sx={{
              backgroundColor: '#1976d2',
              textTransform: 'none',
              color: 'white',
              '&:hover': {
                backgroundColor: '#1565c0',
              
              }
            }}
          >
            Export
          </Button>
          <Menu
            anchorEl={exportAnchorEl}
            open={exportMenuOpen}
            onClose={handleExportClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleExport(false)}>
              <ListItemIcon>
                <FormatListBulleted fontSize="small" />
              </ListItemIcon>
              <ListItemText>Basic Export</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleExport(true)}>
              <ListItemIcon>
                <Description fontSize="small" />
              </ListItemIcon>
              <ListItemText>Detailed Export</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box sx={{maxHeight: "calc(100vh - 210px)", overflow: "auto"}}>
        <TestScenariosList
          testScenarios={testScenarios}
          isLoading={isLoading}
          onDelete={onDeleteTestScenario}
          onArchive={onArchiveTestScenario}
        />
      </Box>
    </MainLayout>
  );
};
function mapStateToProps(state, ownProps){
  return {
    ...ownProps,
    project: state.common.selectedProject
  }
}

export default connect(mapStateToProps)(TestScenarioListPage);