import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  FormGroup
} from "@mui/material";
import LoadingOverlay from "../Common/LoadingOverlay";
import CustomButton from "../Common/CustomButton";
import DeleteDialog from '../Common/DeleteDialog';
import CustomCheckbox from '../Common/CustomCheckbox';
import {
  ArticleOutlined,
  CreateOutlined,
  SaveOutlined,
  Close
} from "@mui/icons-material/";

import { updateVariableSet, deleteVariableSet } from "../../redux-store/variableSetReducers/variableSetActions";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import "../../styles/components/Common/EditDetail.scss";

const EditDetail = ({ projectId, variableSetId, closeDrawer}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const [detail, setDetail] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const selectedVariableSetDetail = useSelector((state) => state.variableSet.selectedVariableSetDetail);
  const isLoading = useSelector((state) => state.variableSet.isLoading);
  const isSaving = useSelector((state) => state.variableSet.isSaving);

  useEffect(() => {
    console.log("selectedVariableSetDetail", selectedVariableSetDetail);
    setInitialState();
  }, []);

  const setInitialState = () => {
    const detailObj = {
      title: selectedVariableSetDetail?.title || "",
      isEnv: selectedVariableSetDetail?.is_environment || false
    };
    setDetail(detailObj);
  };

  const updateVariableSetDetail = async (data) => {
    await dispatch(updateVariableSet({ variableSetId, data: data }));
    setIsEdit(false);
    openSnackbar({
      message: "Variable set details updated successfully.",
      severity: "success",
    });
  };

  const onChange = (name, value) => {
    if (name === "title") updateErrors("title", "");
    setDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateErrors = (key, msg = "") => {
    setErrors((prevState) => {
      const newState = { ...prevState };
      newState[key] = msg;
      if (!msg) delete newState[key];
      return newState;
    });
  };

  const onEdit = () => {
    setInitialState();
    setIsEdit(true);
  };

  const toggleDeleteModal = () => {
    setDeleteDialogOpen((prevState) => !prevState);
  };

  const onDeleteVariableSet = async () => {
    await dispatch(deleteVariableSet({ variableSetId: variableSetId }));
    openSnackbar({
      message: "Variable set deleted successfully.",
      severity: "success",
    });
    closeDrawer();
    history.push("/variable-set/");
  };

  const onSave = () => {
    const formattedDetail = {title: detail.title, is_environment: detail.isEnv};
    let error = false;
    if (!formattedDetail?.title || !formattedDetail.title.trim()) {
      updateErrors("title", "Required");
      error = true;
    }
    if (error) {
      return;
    }
    updateVariableSetDetail(formattedDetail);
  };

  const onCancelEdit = () => {
    setIsEdit(false);
    setInitialState();
  };

  const handleCheckboxChange = (event) => {
    setDetail((prevState) => ({
      ...prevState,
      isEnv: !prevState.isEnv,
    }));
  }

  const renderConfirmDialog = () => {
    return (
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={toggleDeleteModal}
        title="Delete Variable Set?"
        deleteBtnLabel="Delete Permanently"
        onDeleteAction={onDeleteVariableSet}
        hideSecondary={true}
      >
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Are you sure you want to permanently delete this Variable Set?
        </Typography>
      </DeleteDialog>
    );
  };

  if (isLoading) {
    return <LoadingOverlay isLoading={isLoading} />;
  }

  let content = null;
  content = (
    <>
      <div className="section">
        <h6>Title</h6>
        {isEdit ? (
          <TextField
            sx={{ mb: 2, width: "100%" }}
            size="small"
            variant="outlined"
            value={detail?.title}
            onChange={(e) => {
              onChange("title", e.target.value);
            }}
          />
        ) : (
          <Typography>{selectedVariableSetDetail?.title}</Typography>
        )}
        {errors?.title && (
          <Typography sx={{ color: "red" }}>{errors.title}</Typography>
        )}
      </div>
      {/* <div className="section">
        <h6>Variable set type</h6>
        {isEdit && (
          <FormGroup>
            <CustomCheckbox
              checked={detail?.isEnv}
              label="Environment"
              onChange={handleCheckboxChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </FormGroup>
        )}
        {!isEdit && (<Typography>{selectedVariableSetDetail?.is_environment ? "Environment" : "Non Environment"}</Typography>)}
      </div> */}
    </>
  );

  const nonEditModeActions = (
    <Grid container spacing={2} sx={{ flexDirection: "row-reverse" }}>
      <CustomButton
        variant="outlined"
        color="primary"
        disabled={false}
        onClick={onEdit}
      >
        <CreateOutlined sx={{ mr: 1 }} />
        Edit
      </CustomButton>
    </Grid>
  );

  const editModeActions = (
    <Grid container spacing={2}>
      <CustomButton
        variant="outlined"
        sx={{ mr: 2 }}
        color="primary"
        disabled={isSaving}
        onClick={onSave}
      >
        <SaveOutlined sx={{ mr: 1 }} />
        Save
      </CustomButton>
      <CustomButton
        variant="outlined"
        color="primary"
        disabled={isSaving}
        onClick={onCancelEdit}
      >
        <Close sx={{ mr: 1 }} />
        Cancel
      </CustomButton>
    </Grid>
  );

  return (
    <Box sx={{ p: 2, px: 4, color: "black" }} className="editDetail">
      {isSaving && <LoadingOverlay isLoading={isSaving} />}
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          {isEdit ? (
            <>
              <h5>
                <CreateOutlined sx={{ mr: 1 }} />
                Edit Basic Details
              </h5>
            </>
          ) : (
            <>
              <h5>
                <ArticleOutlined sx={{ mr: 1 }} />
                Basic Details
              </h5>
            </>
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          {!isEdit && nonEditModeActions}
        </Grid>
        <Grid item xs={12} md={12}>
          {content}
        </Grid>
        <Grid item xs={12} md={12} style={{ padding: "32px 40px" }}>
          {isEdit && editModeActions}
        </Grid>
        {!isEdit && (
          <div style={{ borderBottom: "1px solid grey", width: "100%" }}></div>
        )}
        <Grid item xs={12} md={12}>
          {!isEdit && (
            <>
              <h5 style={{ color: "#d32f2f" }}>Danger Zone</h5>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                color="error"
                disabled={false}
                onClick={toggleDeleteModal}
              >
                Delete
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      {deleteDialogOpen && renderConfirmDialog()}
    </Box>
  );
};

export default EditDetail;
