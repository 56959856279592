import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Stack, Typography, IconButton, Tooltip, MenuItem, MenuList} from "@mui/material";
import CustomTable from "../Common/CustomTable";
import CustomMenu from '../Common/CustomMenu';
import {MoreVert} from "@mui/icons-material";

const requestTypeColor = {"GET": 'green',"POST": '#ffb900',"PUT": 'blue',"PATCH": 'purple',"DELETE": 'red',"HEAD": 'green',"OPTIONS": 'pink'};

const DataSetList = ({pageNumber, onAction}) => {
  const isLoading = useSelector(state => state.dataSet.isLoading);
  const dataSetList = useSelector(state => state.dataSet.dataSetList || []);
  const [anchorEls, setAnchorEls] = useState({});

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const handleEntityClick = () => {
    // take user to collection page and load entity in new tab
  }

  const getTableContent = () => {
    if (dataSetList?.length === 0 && isLoading) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>Curating test data list...</h3>
        </Box>
      )
    }
    if (dataSetList?.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No data set found.</h3>
        </Box>
      )
    }

    return (
      <CustomTable
        headers={[
          {
            id: 'title',
            type: 'text',
            text: 'Title',
          },
          {
            id: 'entity',
            type: 'text',
            text: 'Entity',
          },
          {
            id: 'count',
            type: 'text',
            text: 'Count',
          },
          {
            id: 'actions',
            type: 'text',
            text: 'Actions',
            align: 'center',
          }
        ]}
        rows={dataSetList.map(
          ({
            id,
            title,
            entity,
            usage_count
          }) => ({
            id,
            cells: [
              {
                id: 'title-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title">
                    <Tooltip title={title} placement="top-start">
                      <Typography variant="body1" className="truncate-1-lines">{title}</Typography>
                    </Tooltip>
                  </Stack>
                ),
                sx: { width: '300px' },
              },
              {
                id: 'entity-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title" onClick={() => {handleEntityClick(entity)}}>
                    <Tooltip title={title} placement="top-start">
                      <Typography variant="body1" className="truncate-1-lines">{entity?.title}</Typography>
                    </Tooltip>
                    <Tooltip title={entity?.url} placement="top-start">
                      <Typography className="truncate-1-lines">
                        <span style={{marginRight: 8, fontWeight: 600, color: entity?.request_type ? requestTypeColor[entity.request_type?.toUpperCase()] : ""}}>
                          {entity?.request_type}
                        </span>
                        {entity?.url}
                      </Typography>
                    </Tooltip>
                  </Stack>
                ),
                sx: { width: '300px' },
              },
              {
                id: 'dataset-count-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status">
                    {usage_count || 0}
                  </Typography>
                ),
                sx: { width: '50px' }
              },
              {
                id: 'actions-' + id,
                type: 'component',
                align: 'center',
                content: (
                  <>
                    <IconButton
                      id={`actions-btn-${id}`}
                      aria-label="actions"
                      onClick={(e) => handleClick(e, id)}
                    >
                      <MoreVert />
                    </IconButton>

                    <CustomMenu
                      labelledBy={'actions-btn-' + id}
                      anchorEl={anchorEls[id]}
                      onClose={() => handleClose(id)}
                    >
                      <MenuList sx={{ minWidth: '100px' }}>
                        <MenuItem onClick={() => {handleClose(id); onAction("edit", id)}}>
                          Edit
                        </MenuItem>
                        {
                          !usage_count ? (
                            <MenuItem onClick={() => {handleClose(id); onAction("delete", id)}}>
                              Delete
                            </MenuItem>
                          ) : null
                        }
                      </MenuList>
                    </CustomMenu>
                  </>
                ),
                sx: { width: '50px' },
              }
            ],
          })
        )}
      />
    );
  }

  return (
    <Box sx={{p: 2}}>
      <Box sx={{ maxHeight: "calc(100vh - 150px)", overflow: "auto" }}>
        {getTableContent()}
      </Box>
    </Box>
  )
}

export default DataSetList;
