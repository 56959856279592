import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import VariableSet from './VariableSet';
import VariableSetListPage from './VariableSetListPage';

const VariableSetPage = function ({ project, variableSetId }) {
  const [currentView, setCurrentView] = useState('');
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (variableSetId) {
      setCurrentView('variableSet');
    } else {
      setCurrentView('variableSetList');
      // Set default tab if none specified
      if (!location.search.includes('tab=')) {
        history.replace({ 
          pathname: location.pathname,
          search: location.search ? `${location.search}&tab=environments` : '?tab=environments'
        });
      }
    }
  }, [variableSetId, location]);

  let content = null;
  if (currentView === 'variableSetList') {
    content = (
      <VariableSetListPage 
        project={project} 
        defaultTab={location.search.includes('tab=variables') ? 1 : 0}
      />
    );
  } else if (currentView === 'variableSet') {
    content = <VariableSet project={project} variableSetId={variableSetId} />;
  }

  return <Box>{content}</Box>;
};

export default VariableSetPage;