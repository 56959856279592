import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import {Box, Stack, Typography, Avatar, Tooltip} from "@mui/material";
import CustomChip from '../Common/CustomChip';
import CustomTable from "../Common/CustomTable";
import BuildReportFilters from './BuildReportFilters';

import { BUILD_STATUS_TO_COLOR } from '../../config/constants';
import { getAllBuilds } from '../../redux-store/testPlanBuildReducers/testPlanBuildActions';

const TestPlanBuilds = ({projectId, pageNumber, setPageNumber}) => {
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  const lastFetchedPage = useRef(pageNumber);
  const builds = useSelector(state => state.testPlanBuild.testPlanBuildList);
  const isLoading = useSelector(state => state.testPlanBuild.isLoading);
  const appliedFilters = useSelector(state => state.buildReport.appliedFilters.testPlan);
  const defaultFilters = useMemo(() => ({
    status: "",
    environmentSetId: ""
  }), []);

  useEffect(() => {
    fetchAllBuilds();
  }, []);

  useEffect(() => {
    if (pageNumber !== 1)
      setPageNumber(1);
    else
      fetchAllBuilds();
  }, [appliedFilters]);

  useEffect(() => {
    fetchAllBuilds();
  }, [pageNumber]);

  const fetchAllBuilds = async () => {
    const formattedFilters = {...appliedFilters};
    formattedFilters.environment_set_id = formattedFilters?.environmentSetId ? formattedFilters.environmentSetId : "";
    delete formattedFilters.environmentSetId;
    await dispatch(
      getAllBuilds({
        filters: formattedFilters,
        projectId: projectId,
        pageNumber,
        pageSize: 10
      })
    );
    lastFetchedPage.current = pageNumber;
    isInitialMount.current = false;
  }

  const onClickBuild = (e, testPlanId, buildId) => {
    window.open(`${window.location.origin}/test-plans/${testPlanId}/build/${buildId}`, "_blank");
  };

  const getTableContent = () => {
    if (builds.length === 0 && isLoading) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>Curating builds data...</h3>
        </Box>
      )
    }
    if (builds.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }

    return (
      <CustomTable
        headers={[
          {
            id: 'buildNumber',
            type: 'text',
            text: 'Build Number',
          },
          {
            id: 'testPlan',
            type: 'text',
            text: 'Test Plan',
          },
          {
            id: 'triggeredVia',
            type: 'text',
            text: 'Triggered Via',
          },
          {
            id: 'Result',
            type: 'text',
            text: 'Run Result',
          },
          {
            id: 'executionTime',
            type: 'text',
            text: 'Execution Time',
          },
          {
            id: 'tags',
            type: 'text',
            text: 'Tags',
          },
          {
            id: 'owner',
            type: 'text',
            text: 'Owner',
          },
          {
            id: 'total_count',
            type: 'text',
            text: 'Total',
          },
          {
            id: 'passed_count',
            type: 'text',
            text: 'Passed',
          }
        ]}
        rows={builds.map(
          ({
            id,
            build_number,
            test_plan,
            started_at,
            execution_time,
            status,
            environment_set,
            triggered_via,
            tags,
            owner,
            source_code,
            test_plan_id,
            total_count,
            passed_count
          }) => ({
            id,
            onClick: (e) => onClickBuild(e, test_plan?.id || test_plan_id, id),
            cells: [
              {
                id: id,
                type: 'text',
                text: 'TR-00' + build_number,
                sx: { width: '100px', cursor: 'pointer' }
              },
              {
                id: 'title-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title" sx={{ cursor: 'pointer' }}>
                    <Tooltip title={test_plan?.title || source_code} placement="top-start">
                      <Typography variant="body1" className="truncate-1-lines">
                        {test_plan?.title || source_code}
                      </Typography>
                    </Tooltip>
                  </Stack>
                ),
                sx: { width: '180px', cursor: 'pointer' }
              },
              {
                id: 'triggeredVia-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status" sx={{ cursor: 'pointer' }}>
                    {triggered_via}
                  </Typography>
                ),
                sx: { width: '100px', cursor: 'pointer' }
              },
              {
                id: 'build-status-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" className="chips lastrun" flexWrap="wrap" spacing={1} sx={{ cursor: 'pointer' }}>
                    <CustomChip
                      key={environment_set?.id || `build-status`}
                      className={status}
                      title={status}
                      label={
                        <Tooltip title={status} placement="top-start">
                          <span>
                            {environment_set?.title || '-'}:{' '}
                            <span
                              style={{
                                color: BUILD_STATUS_TO_COLOR[status] || 'black',
                                cursor: 'pointer'
                              }}
                            >
                              {started_at
                                ? dayjs(started_at * 1000).fromNow()
                                : status || '-'}
                            </span>
                          </span>
                        </Tooltip>
                      }
                    />
                  </Stack>
                ),
                sx: { width: '150px', cursor: 'pointer' }
              },
              {
                id: 'executionTime-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status" sx={{ cursor: 'pointer' }}>
                    {execution_time ? `${execution_time.toFixed(2)} seconds` : ""}
                  </Typography>
                ),
                sx: { width: '100px', cursor: 'pointer' }
              },
              {
                id: 'tags-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1} className="chips" sx={{ cursor: 'pointer' }}>
                    {(tags || []).map((tag) => (
                      <CustomChip key={tag} label={tag} />
                    ))}
                  </Stack>
                ),
                sx: { width: '240px', cursor: 'pointer' }
              },
              {
                id: 'owner-' + id,
                type: 'component',
                content: owner ? (
                  <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: 'pointer' }}>
                    <Avatar
                      alt={owner.name}
                      src={owner.avatar}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body1" className="truncate-2-lines">
                      {owner.name}
                    </Typography>
                  </Stack>
                ) : null,
                sx: { width: '140px', cursor: 'pointer' }
              },
              {
                id: 'totalCount-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status" sx={{ cursor: 'pointer' }}>
                    {total_count}
                  </Typography>
                ),
                sx: { width: '100px', cursor: 'pointer' }
              },
              {
                id: 'passedCount-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status" sx={{ cursor: 'pointer' }}>
                    {passed_count}
                  </Typography>
                ),
                sx: { width: '100px', cursor: 'pointer' }
              }
            ],
          })
        )}
      />
    );
  }

  return (
    <Box sx={{p: 2}}>
      <BuildReportFilters sourceType="testPlan" initialFilters={appliedFilters} defaultFilters={defaultFilters} allowedFilters={["status", "environment"]} />
      <Box sx={{ maxHeight: "calc(100vh - 280px)", overflow: "auto" }}>
        {getTableContent()}
      </Box>
    </Box>
  )
}

export default TestPlanBuilds;